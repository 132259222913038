import { autocomplete } from '@algolia/autocomplete-js';
import { BaseItem } from "@algolia/autocomplete-core";
import { visit } from "@hotwired/turbo"

import MapSuburbsController from "./map_suburbs_controller";
import { adminDenyListEntryPath, addressesAdminDenyListEntriesPath } from "src/routes";
import customerMarkerIcon from "images/map/customer-marker-icon.svg";

interface BlockedAddressDatum {
  id: number;
  latitude: string;
  longitude: string;
  address: string;
}

export default class extends MapSuburbsController {
  static targets = ["map", "searchInputContainer", "blockedAddressData"];

  declare searchInputContainerTarget?: HTMLInputElement;
  declare hasSearchInputContainerTarget: boolean;
  declare blockedAddressDataTarget: HTMLDivElement;

  autocompleteApi?: { setQuery: (query: string) => void; destroy: () => void };
  sessionToken?: google.maps.places.AutocompleteSessionToken;
  addressMarkers = new Map<number, google.maps.Marker>();

  async connect(): Promise<void> {
    await super.connect();
    this.setupAutocomplete();
  }

  disconnect(): void {
    this.autocompleteApi?.destroy();
    this.autocompleteApi = undefined;

    this.addressMarkers.forEach((marker) => marker.setMap(null));
    this.addressMarkers.clear();

    super.disconnect();
  }

  private setupAutocomplete(): void {
    if (!this.hasSearchInputContainerTarget || !this.searchInputContainerTarget) return;

    this.autocompleteApi = autocomplete({
      container: this.searchInputContainerTarget,
      classNames: {
        form: "input",
        item: "p-2",
      },
      defaultActiveItemId: 0,
      initialState: { query: this.data.get("initial-value") ?? "" },
      getSources: () => {
        return [{
          sourceId: "autocomplete-address", // arbitrary
          getItems: async ({ query } : { query: string }): Promise<BaseItem[]> => {
            // Start a new session if we don't have one active, so we can get combined session pricing on the completion
            this.sessionToken ??= new google.maps.places.AutocompleteSessionToken();

            const request = {
              input: query,
              includedPrimaryTypes: ["street_address"],
              includedRegionCodes: ["nz"],
              language: "en-NZ",
              region: "nz",
              sessionToken: this.sessionToken,
            };
            const result = await google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(request);
            return result.suggestions.map((suggestion) => { return { id: suggestion.placePrediction?.placeId, prediction: suggestion.placePrediction } });
          },
          templates: {
            item({ item, html }) {
              const prediction = (item.prediction as google.maps.places.PlacePrediction);
              return html`<div>${prediction.mainText?.text}</div><div class="description">${prediction.secondaryText?.text}</div>`;
            },
            noResults({ state, html }) {
              return html`Sorry, couldn't find an address for "${state.query}"`;
            },
          },
          onSelect: async ({ item, setQuery }) => {
            // Calling toPlace() terminates the session, and we want to reset or else we'll get charged per-request thereafter.
            this.sessionToken = undefined;

            const prediction = (item.prediction as google.maps.places.PlacePrediction);
            const place = prediction.toPlace();
            await place.fetchFields({ fields: ['location'] });

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const latlng = place.location!;

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (this.map && this.map.getZoom()! < 16) this.map?.setZoom(16); // don't load too much at once
            this.map?.setCenter(latlng);

            // Reset for the next input
            setQuery("");
          },
        }];
      },
    });
  }

  protected createMap(): void {
    super.createMap();

    if (!this.map) return; // never happens

    // Stop people trying to load too much at once
    this.map?.setOptions({ minZoom: 13 });
  }

  protected setInitialBounds(): void {
    this.map?.setCenter(new google.maps.LatLng(-45.752, 166.58));
    this.map?.setZoom(16);

    this.updateStateFromHash();
  }

  private updateHashFromState(): void {
    const bounds = this.map?.getBounds();
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      window.history.replaceState(undefined, "", `#n${ne.lat()},e${ne.lng()},s${sw.lat()},w${sw.lng()}`);
    }
  }

  private updateStateFromHash(): void {
    const match = window.location.hash?.match(/#n([\d.-]+),e([\d.-]+),s([\d.-]+),w([\d.-]+)/)
    if (match) {
      const ne = new google.maps.LatLng(parseFloat(match[1]), parseFloat(match[2]));
      const sw = new google.maps.LatLng(parseFloat(match[3]), parseFloat(match[4]));
      this.map?.fitBounds(new google.maps.LatLngBounds(sw, ne), 0);
    }
  }

  protected boundsChanged(): void {
    this.updateHashFromState();
    const bounds = this.map?.getBounds();
    if (bounds) visit(this.addBoundsToPath(addressesAdminDenyListEntriesPath(), bounds), { frame: "results" })
  }

  blockedAddressDataTargetConnected(): void {
    const data = JSON.parse(this.blockedAddressDataTarget.innerText);

    const oldMarkers = this.addressMarkers;
    this.addressMarkers = new Map<number, google.maps.Marker>();

    data.forEach((datum: BlockedAddressDatum) => {
      const marker = oldMarkers.get(datum.id) ?? new google.maps.Marker({
        map: this.map,
        position: { lat: parseFloat(datum.latitude), lng: parseFloat(datum.longitude) },
        zIndex: 1,
        title: datum.address,
        opacity: 0.75,
        icon: {
          url: customerMarkerIcon,
          scaledSize: new google.maps.Size(24, 24),
          anchor: new google.maps.Point(12, 12),
        },
      });
      marker.addListener("click", () => { window.location.href = adminDenyListEntryPath({ id: datum.id }) });
      oldMarkers.delete(datum.id);
      this.addressMarkers.set(datum.id, marker);
    });

    oldMarkers.forEach((marker) => marker.setMap(null));
  }
}
