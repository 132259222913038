import { Controller } from "@hotwired/stimulus";

export default class IncidentController extends Controller {
  static targets = [
    "search",
    "hidden",
    "participantList"
  ];

  declare searchTarget: HTMLInputElement;
  declare hiddenTarget: HTMLInputElement;
  declare participantListTarget: HTMLElement;

  addParticipant() {
    if (
      this.hiddenTarget.value == "" ||
      this.participantAlreadyExists(this.hiddenTarget.value)
    ) {
      return;
    } else {
      const participant = document.createElement("div");
      participant.classList.add("participant", "list-item");
      participant.dataset["id"] = this.hiddenTarget.value;

      const content = `
        <p class="is-flex-grow-1">${this.searchTarget.value}</p>
        <div class="select is-small">
          <select name="incident_report[participants][${this.hiddenTarget.value}]" required>
            <option></option>
            <option value="cause">cause</option>
            <option value="affected">affected</option>
            <option value="observer">observer</option>
          </select>
        </div>

        <button class="button is-small is-align-items-end ml-1" data-action="click->incident#removeParticipant">
          <span class="icon">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </span>
        </button>
      `;

      participant.insertAdjacentHTML("afterbegin", content);
      this.participantListTarget.appendChild(participant);

      this.searchTarget.value = "";
      this.hiddenTarget.value = "";
    }
  }

  removeParticipant(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const participant = target.closest(".participant") as HTMLInputElement;
    if (participant != undefined) participant.remove();
  }

  private participantAlreadyExists(id: string) {
    return Array.prototype.some.call(this.participantListTarget.children, (el) => el.dataset.id === id);
  }
}
