import React, { useState, useEffect, ChangeEvent, KeyboardEvent, useRef } from "react";
import TextAreaAutosize from "react-textarea-autosize";

import Logo from "images/logos/delivereasy-logo-icon.svg";

import { Client, UserType, ChatType } from "./client";
import { Conversation, HistoryStatus } from "./conversation";
import { Message } from "./message";
import { ConversationList } from "./conversation_list_component";
import { MessageList } from "./message_list_component";

import DispatchApiClient, { DashboardConfig, DashboardContext } from "../dispatch/dispatch_api_client";
import DriverTrackerMap, { StoreInfo } from "../dispatch/driver_tracker_map";
import { DriverComponent } from "../dispatch/driver";
import { Dashboard } from "../dispatch/model";

import { adminChatsPath, adminDriverPath } from "src/routes";
import { CurrentTimeContext } from "src/admin/dispatch/delivery";

const ChatComponent = ({ client, dispatchApiClients, stores, initConversationId }: { client: Client; dispatchApiClients: Map<number, DispatchApiClient>; stores?: StoreInfo[]; initConversationId: number | null }): React.JSX.Element => {
  const [delayedInit, setDelayedInit] = useState(false);
  const [conversations, setConversations] = useState(client.conversations);
  const [dashboardUpdatedAt, setDashboardUpdatedAt] = useState(new Date());
  const trackerMapRef = useRef<DriverTrackerMap | null>(null);

  const handleDashboardUpdate = () => {
    setDashboardUpdatedAt(new Date());
  }

  const focusConversation = (selectedConversation?: Conversation) => {
    if (selectedConversation) {
      if (selectedConversation.historyStatus == HistoryStatus.Unloaded) {
        client.loadHistory([selectedConversation]);
      }

      client.setReadCursor(selectedConversation);
    }

    setConversations((cvs) => {
      return cvs.map((c: Conversation) => {
        c.isActiveConversation =
          c.chatChannelName == selectedConversation?.chatChannelName;
        return c;
      });
    });

    trackerMapRef.current?.focusDriver(selectedConversation?.id);
  };

  const sendMessage = (message: string) => {
    if (message?.trim() && selectedConversation)
      client.sendMessage(
        selectedConversation,
        new Message({
          content: message.trim(),
          authorUUID: client.uuid,
          authorType: UserType.Admin,
          attemptedSendAt: new Date(),
        })
      );
  };

  const sendAcknowledgement = () => {
    sendMessage("\uD83D\uDC4D");
  }

  useEffect(() => {
    if (!initConversationId) return;

    setConversations((cvs) => {
      return cvs.map((c: Conversation) => {
        c.isActiveConversation =
          c.id == initConversationId;
        return c;
      });
    });

    trackerMapRef.current?.focusDriver(initConversationId);
  }, [delayedInit, initConversationId]);

  useEffect(() => {
    const handleConversationsChanged = (newConversations: Conversation[]) => {
      // newConversations == conversations in terms of the React object differ
      // it seems to use some kind of object identity. We use a array spread to
      // get around this. Stupid javascript. Pick a thing, pass-by-reference or
      // pass-by-value, this combo bullshit is frustrating.
      setConversations([...newConversations]);

      if (!delayedInit) setDelayedInit(true);
    };

    client.conversationsUpdated.addListener(handleConversationsChanged);

    return function cleanup() {
      client.conversationsUpdated.removeListener(handleConversationsChanged);
    }
  }, [client.conversationsUpdated, delayedInit]);

  useEffect(() => {
    dispatchApiClients.forEach((dispatchApiClient) => {
      dispatchApiClient.dashboardUpdated.addListener(handleDashboardUpdate);
    });

    // Set a timer to periodically update the 'update x mins ago' text for tracking; the actual
    // dashboard polling updates happen in DispatchApiClient and result in a dashboardUpdated.
    const intervalTimer = window.setInterval(handleDashboardUpdate, 5000);

    return function cleanup() {
      clearInterval(intervalTimer);

      dispatchApiClients.forEach((dispatchApiClient) => {
        dispatchApiClient.dashboardUpdated.removeListener(handleDashboardUpdate);
      });
    }
  }, [dispatchApiClients]);


  useEffect(() => {
    const mapElement = document.getElementById('driver_tracking_map');
    const lastPingElement = document.getElementById('driver_tracking_last_ping')

    if (mapElement && lastPingElement && stores) {
      const trackerMap = new DriverTrackerMap(mapElement, lastPingElement, true, stores);

      trackerMapRef.current = trackerMap;

      dispatchApiClients.forEach((dispatchApiClient) => {
        dispatchApiClient.dashboardUpdated.addListener(trackerMap.handleDashboardUpdate);
        dispatchApiClient.driverTrackingUpdated.addListener(trackerMap.handleDriverTrackingUpdate);
      });

      return function cleanup() {
        trackerMap.dispose();

        dispatchApiClients.forEach((dispatchApiClient) => {
          dispatchApiClient.dashboardUpdated.removeListener(trackerMap.handleDashboardUpdate);
          dispatchApiClient.driverTrackingUpdated.removeListener(trackerMap.handleDriverTrackingUpdate);
        });
      }
    }
  }, [dispatchApiClients, stores]);


  const [infoIsActive, setInfoActive] = useState(false);

  const toggleInfo = () => {
    setInfoActive(!infoIsActive);
  };

  const selectedConversation = conversations.find((c) => {
    return c.isActiveConversation;
  });

  const dispatchApiClient = selectedConversation?.areaId ? dispatchApiClients.get(selectedConversation?.areaId) : undefined;
  const dashboard = dispatchApiClient?.dashboard;

  return (
    <>
      <ConversationsPane
        client={client}
        conversations={conversations}
        focusConversation={focusConversation}
        isActive={!selectedConversation}
        dashboard={dashboard}
      />

      <ChatMessagesPane
        client={client}
        conversation={selectedConversation}
        handleSendMessage={sendMessage}
        handleAcknowledgement={sendAcknowledgement}
        clearFocusedConversation={focusConversation}
        toggleInfo={toggleInfo}
        isActive={!infoIsActive && !!selectedConversation}
        key={selectedConversation?.chatChannelName}
      />

      <DetailsPane
        conversation={selectedConversation}
        isActive={infoIsActive && !!selectedConversation}
        dispatchApiClient={dispatchApiClient}
        dashboardUpdatedAt={dashboardUpdatedAt}
        toggleInfo={toggleInfo}
      />
    </>
  );
};

const ConversationsPane = ({
  client,
  conversations,
  focusConversation,
  isActive,
  dashboard,
}: {
  client: Client;
  conversations: Conversation[];
  focusConversation: (selectedConversation: Conversation) => void;
  isActive: boolean;
  dashboard?: Dashboard;
}): React.JSX.Element => {
  return (
    <div
      className={`column p-0 chat-view chat-view--conversations is-flex is-flex-direction-column${
        isActive ? "" : " is-hidden-mobile"
      }`}
    >
      <div className="level p-4 is-mobile chat-level is-stuck-top">
        <div className="level-left">
          <div className="level-item">
            <img src={Logo} alt="Delivereasy Admin" className="logo" />
          </div>

          <div className="level-item">
            <h1 className="title">{client.chatType == ChatType.Manager ? "AC chat" : "Chat"}</h1>
          </div>
        </div>

        <div className="level-right">
          <ChatDropdownComponent />
        </div>
      </div>

      <div className="is-flex is-flex-direction-column is-flex-grow-1 has-vertical-scrollbars">
        <ConversationList
          conversations={conversations}
          focusConversation={focusConversation}
          client={client}
          dashboard={dashboard}
        />
      </div>
    </div>
  );
};

const MessagePaneHeader = ({
  selectedConversation = undefined,
  clearFocusedConversation,
  toggleInfo,
}: {
  selectedConversation: Conversation | undefined;
  clearFocusedConversation: () => void;
  toggleInfo: () => void;
}): React.JSX.Element => {
  return (
    <div className="level p-4 is-mobile chat-level chat-element-raised">
      <div className="level-left">
        <div className="level-item">
          <button className="button is-text" onClick={clearFocusedConversation}>
            <span className="icon">
              <i className="fas fa-arrow-left fa-lg" aria-hidden="true"></i>
            </span>
          </button>
        </div>

        <div className="level-item">
          <span className="icon">
            {selectedConversation ?
              <a href={adminDriverPath(selectedConversation.id)} target="_blank" rel="noreferrer"><i className="fas fa-user-circle fa-2x" aria-hidden="true"></i></a>
              : <></>}
          </span>
        </div>

        <div className="level-item">
          <div>
            <p className="is-size-5 has-text-weight-medium">
              {selectedConversation?.name}
            </p>

            <p className="heading">
              {selectedConversation
                ? `Driving in ${selectedConversation.areaName}`
                : ``}
            </p>
          </div>
        </div>
      </div>

      <div className="level-right">
        {selectedConversation ?
          <div className="level-item">
            <div>
              <p className="is-size-6 has-text-right">
                {selectedConversation.internalNote != '' ? selectedConversation.internalNote : <>&nbsp;</>}
              </p>
              <p className="heading has-text-right">
                <span className="icon" style={{ height: "inherit" }}>
                  <i className="fas fa-phone"></i>
                </span>
                  <a data-number={selectedConversation.mobileNumber} data-action="click->phone-widget#call">{selectedConversation.mobileNumber}</a>
              </p>
            </div>
          </div>
        : <></>}
        <div className="level-item">
          <button className="button is-text is-active" onClick={toggleInfo}>
            <span className="icon is-large">
              <i className="fas fa-info-circle fa-lg" aria-hidden="true"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const ChatMessagesPane = ({
  client,
  conversation = undefined,
  handleSendMessage,
  handleAcknowledgement,
  clearFocusedConversation,
  toggleInfo,
  isActive,
}: {
  client: Client;
  conversation: Conversation | undefined;
  handleSendMessage: (message: string) => void;
  handleAcknowledgement: () => void;
  clearFocusedConversation: () => void;
  toggleInfo: () => void;
  isActive: boolean;
}): React.JSX.Element => {
  const messageListContent = conversation ? (
    <MessageList conversation={conversation} client={client} />
  ) : (
    <div className="level is-flex-grow-1 has-text-centered">
      <div className="level-item">No conversation selected</div>
    </div>
  );

  return (
    <div
      className={`column p-0 chat-view chat-view--messages is-flex is-flex-direction-column ${
        isActive ? "" : " is-hidden-mobile"
      }`}
    >
      <div className="is-flex is-flex-direction-column is-justify-content-space-between is-flex-grow-1">
        <MessagePaneHeader
          selectedConversation={conversation}
          clearFocusedConversation={clearFocusedConversation}
          toggleInfo={toggleInfo}
        />

        <div className="column p-4 is-gap-2 has-vertical-scrollbars is-flex is-flex-direction-column has-background-light chat-message-canvas">
          {messageListContent}
        </div>

        <MessageComposer handleSendMessage={handleSendMessage} disabled={!conversation} handleAcknowledgement={handleAcknowledgement} />
      </div>
    </div>
  );
};

const MessageComposer = ({
  handleSendMessage,
  handleAcknowledgement,
  disabled,
}: {
  handleSendMessage: (message: string) => void;
  handleAcknowledgement: () => void;
  disabled: boolean;
}): React.JSX.Element => {
  const [draft, setDraft] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current != null) {
      textAreaRef.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition]);

  const updateDraft = (e: ChangeEvent) => {
    if (e.target instanceof HTMLTextAreaElement && textAreaRef.current != null) {
      setDraft(e.target.value);
      setCursorPosition(textAreaRef.current.selectionEnd);
    }
  };

  const handleSendMessageButton = () => {
    handleSendMessage(draft);
    setDraft("");
    setCursorPosition(0);
  };

  const handleAcknowledgementButton = () => {
    handleAcknowledgement();
  };

  const handleSendMessageKeypress = (e: KeyboardEvent) => {
    if (e.key == "Enter" && e.ctrlKey) {
      if (textAreaRef.current != null) {
        const position = textAreaRef.current.selectionEnd;
        const newDraft = draft.substring(0, position) + '\n' + draft.substring(position);

        setDraft(newDraft)
        setCursorPosition(position + 1)
      }
    } else if (e.key == "Enter" && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault()
      handleSendMessage(draft);
      setDraft("");
      setCursorPosition(0);
    }
  };

  return (
    <div className="level chat-bottom-bar chat-element-raised p-4">
      <div className="field has-addons is-flex-grow-1">
        <div className="control is-expanded">
          <TextAreaAutosize
            ref={textAreaRef}
            placeholder="Aa"
            className="input no-resize"
            value={draft}
            onChange={updateDraft}
            onKeyPress={handleSendMessageKeypress}
            disabled={disabled}
          />
        </div>
        <div className="control is-flex is-align-items-end">
          <button className="button" onClick={handleSendMessageButton} disabled={disabled}>
            Send
          </button>
          <button className="button is-text" onClick={handleAcknowledgementButton} disabled={disabled}>
            <span className="icon">
              <i className="fas fa-thumbs-up" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const ChatDropdownComponent = (): React.JSX.Element => {
  const [isActive, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!isActive);
  };

  const showModal = () => {
    const modal = document.querySelector("#areas-modal");
    if (modal) modal.classList.add("is-active");
  };

  const collapseAllSections = () => {
    const sections: NodeListOf<Element> = document.querySelectorAll(".group-collapsible[open]");
    sections.forEach(section => section.removeAttribute("open"));
  };

  return (
    <div className={`dropdown is-right ${isActive ? " is-active" : ""}`}>
      <button className="button is-text" onClick={toggleActive}>
        <span className="icon">
          <i className="fas fa-ellipsis-v"></i>
        </span>
      </button>

      <div className="dropdown-menu">
        <div className="dropdown-content">
          <a className="dropdown-item" onClick={showModal}>
            <span className="icon mr-2">
              <i className="fas fa-sliders-h" aria-hidden="true"></i>
            </span>

            <span>Chat areas</span>
          </a>

          <a className="dropdown-item" onClick={collapseAllSections}>
            <span>Collapse all sections</span>
          </a>

          <hr className="dropdown-divider" />

          <a className="dropdown-item" href={adminChatsPath()}>
            <span>Exit chat</span>
          </a>
        </div>
      </div>
    </div>
  );
};

const DetailsPane = ({
  isActive,
  conversation,
  dispatchApiClient,
  dashboardUpdatedAt,
  toggleInfo,
}: {
  isActive: boolean;
  conversation?: Conversation;
  dispatchApiClient?: DispatchApiClient;
  dashboardUpdatedAt: Date;
  toggleInfo: () => void;
}): React.JSX.Element => {
  const dashboard = dispatchApiClient?.dashboard;
  const driver = dashboard?.drivers.find((driver) => conversation && driver.pubnubUuid == conversation?.uuid);

  return (
    <div
      className={`column p-0 chat-view chat-view--infopane is-size-7${
        isActive ? " is-hidden-tablet" : " is-hidden-mobile"
      }`}
    >
      <div className="level p-4 is-mobile chat-level">
        <div className="level-left">
          <div className="level-item is-hidden-tablet">
            <button className="button is-text" onClick={toggleInfo}>
              <span className="icon">
                <i className="fas fa-arrow-left fa-lg" aria-hidden="true"></i>
              </span>
            </button>
          </div>

          <div className="level-item">
            <div className="is-size-5 has-text-weight-medium">Info</div>
          </div>
        </div>
      </div>

      <div
        id="driver_tracking_map"
        data-area-tracker-target="map"
        style={{ height: "400px", backgroundColor: "#eee" }}
      ></div>

      <div
        id="driver_tracking_last_ping"
        data-area-tracker-target="lastUpdateTime"
      ></div>

      {dispatchApiClient && dashboard && driver ?
        <DashboardContext.Provider
          value={new DashboardConfig(dispatchApiClient)}
        >
          <CurrentTimeContext.Provider value={dashboardUpdatedAt}>
            <DriverComponent
              key={conversation?.uuid}
              dashboard={dashboard}
              driver={driver}
            />
          </CurrentTimeContext.Provider>
        </DashboardContext.Provider>
      :
      <></>
      }
    </div>
  );
};

export { ChatComponent };
