import MapSuburbsController from "./map_suburbs_controller";
import { adminSuburbsPath } from "src/routes";

export default class extends MapSuburbsController {
  placeBoundaryPolylines: google.maps.Polyline[] = [];

  protected async loadDataFrom(path: string): Promise<void> {
    const response = await fetch(path, { credentials: 'same-origin' });
    const json = await response.json();

    for (const suburb of json.suburbs) {
      if (!this.suburbPolygons.has(suburb.id)) {
        this.suburbPolygons.set(suburb.id, this.renderSuburb(suburb));
      }
    }

    if (json.mapPlace) {
      this.renderPlaceBoundary(json.mapPlace.boundaries);
    }
  }

  protected setInitialBounds(): void {
    const bounds = new google.maps.LatLngBounds();

    for (const path of this.placeBoundaryPolylines) {
      path.getPath().forEach((latlng) => bounds.extend(latlng));
    }

    this.map?.fitBounds(bounds, 0);
  }

  protected boundsChanged(): void {
    // We already have the place boundary loaded by the very first call to loadDataFrom, so from now
    // point we'll just need to load suburbs from the normal path.
    const bounds = this.map?.getBounds();
    if (bounds) this.loadDataFrom(this.addBoundsToPath(adminSuburbsPath({ format: 'json' }), bounds));
  }

  private renderPlaceBoundary(placeBoundaries: string[]) {
    const paths = placeBoundaries.map((polyline) => this.decodePolyline(polyline));
    this.placeBoundaryPolylines = paths.map((path) => new google.maps.Polyline({
      map: this.map,
      path: path,
      strokeOpacity: 0,
      icons: [
        {
          offset: "0",
          repeat: "7.5px",
          icon: {
            path: "M 0,-0.5 0,0.5",
            strokeOpacity: 1,
            scale: 2,
          },
        },
      ],
    }));
  }
}
