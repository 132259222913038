import { Controller } from "@hotwired/stimulus";

type Event = {
  target: HTMLInputElement;
}

export default class extends Controller {
  static targets = ["integrationPartner", "externalStoreId", "externalStoreIdInput", "partnerFields", "existingPartnerCheckbox", "partnerName", "partnerNameInput"]

  declare externalStoreIdTarget: HTMLInputElement;
  declare externalStoreIdInputTarget: HTMLInputElement;
  declare integrationPartnerTarget: HTMLInputElement;
  declare partnerFieldsTarget: HTMLElement;
  declare hasPartnerFieldsTarget: boolean;
  declare existingPartnerCheckboxTarget: HTMLInputElement;
  declare partnerNameTarget: HTMLElement;
  declare partnerNameInputTarget: HTMLInputElement;

  connect() {
    this.setFormFieldVisibility()
  }

  onChangeIntegrationPartner(event: Event) {
    this.setFormFieldVisibility(event.target.value);
  }

  onChangeExistingPartnerCheckbox(event: Event) {
    const checked = event.target.checked;

    this.partnerNameInputTarget.value = "";
    this.partnerNameTarget.classList.toggle("is-hidden", !checked);

    checked ? this.partnerNameInputTarget.setAttribute("required", "true") :
      this.partnerNameInputTarget.removeAttribute("required");
  }

  private setFormFieldVisibility(integrationPartnerValue?: string) {
    const integrationPartner = integrationPartnerValue || this.integrationPartnerTarget.value;
    const isDelivereasyIntegration = integrationPartner === "simple";

    this.externalStoreIdTarget.classList.toggle("is-hidden", ["", "simple"].includes(integrationPartner));

    isDelivereasyIntegration ? this.externalStoreIdInputTarget.removeAttribute("required") :
      this.externalStoreIdInputTarget.setAttribute("required", "true");

    if (this.hasPartnerFieldsTarget) {
      this.partnerFieldsTarget.classList.toggle("is-hidden", !isDelivereasyIntegration);
      this.existingPartnerCheckboxTarget.checked = false;
      this.partnerNameTarget.classList.toggle("is-hidden", true);
      this.partnerNameInputTarget.value = "";
      this.partnerNameInputTarget.removeAttribute("required");
    }
  }
}
