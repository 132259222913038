import * as Sentry from "@sentry/browser";
import Signal from "../../signal";
import { Dashboard, Driver, Delivery, Item, DeliveryGroup, DeliveryTracking, DriverTrackingUpdate, DeliveryTrackingStep, ApiClientInterface } from "./model";
import { DeliveryStatus } from "../delivery_status";
import {
  addAdminDispatchDashboardDriverPath,
  allocateAdminDispatchDashboardDeliveryPath,
  allocateAdminDispatchDashboardGroupPath,
  createNoteAdminDispatchDashboardDriverPath,
  sendDelayMessageAdminDispatchDashboardDeliveryPath,
  delayPrepAdminDispatchDashboardDeliveryPath,
  updateDashNoteAdminDispatchDashboardDeliveryPath,
  groupAdminDispatchDashboardDeliveryPath,
  lockAdminDispatchDashboardGroupPath,
  mergeAdminDispatchDashboardGroupPath,
  removeAdminDispatchDashboardDriverPath,
  compactAdminDispatchDashboardGroupsPath,
  unlockAdminDispatchDashboardGroupPath,
  adminDispatchDashboardUpdatePanicPath,
  detailsAdminDispatchDashboardDeliveryPath,
  updateDispatcherAdminAreaPath,
} from 'src/routes';
import { adminCable } from "src/admin_cable";
import { createContext, useContext } from "react";

interface DispatchDashboardChannelData {
  dashboard?: Dashboard;
  delivery?: unknown;
  group?: unknown;
  areaOpen?: string;
  areaClosed?: string;
  dispatcherId?: number | null;
  dispatcherName?: string | null;
  nextAvailablePickupTime?: string;
}

interface DispatchTrackingChannelData {
  driverId: number;
  receivedAt: string;
  latitude: number;
  longitude: number;
  heading?: number;
  deliveries: {
    deliveryId: number;
    status: string;
    secondsToPickup?: number;
    routeToPickup?: DeliveryTrackingStep[];
    secondsToDropoff?: number;
    routeToDropoff?: DeliveryTrackingStep[];
  }[];
}

export default class {
  areaId: number;
  csrfToken: string;
  dashboard?: Dashboard;
  deliveryTrackingByDriverId: Map<number, DeliveryTracking[]> = new Map();
  subscriptions: ActionCable.Subscription[] = [];
  disconnectedAt?: number;

  readonly dashboardConnected = new Signal<boolean>();
  readonly dashboardUpdated = new Signal<Dashboard>();
  readonly driverTrackingUpdated = new Signal<DriverTrackingUpdate>();

  constructor(areaId: number, csrfToken: string) {
    this.areaId = areaId;
    this.csrfToken = csrfToken;
  }

  start() : void {
    console.log("Subscribing to dispatch dashboard updates");
    this.subscribeToUpdates();
    document.addEventListener("visibilitychange", this.debugVisibilityChange);
    document.addEventListener("freeze", this.debugFreeze);
    document.addEventListener("resume", this.debugResume);
  }

  stop() : void {
    console.log("Unsubscribing from dispatch dashboard updates");
    this.unsubscribeFromUpdates();
    document.removeEventListener("resume", this.debugResume);
    document.removeEventListener("freeze", this.debugFreeze);
    document.removeEventListener("visibilitychange", this.debugVisibilityChange);
  }

  // Some more logging to help debug the lost-updates problems
  debugVisibilityChange(): void { console.log(`Document visibilityChange event, visibilityState now ${document.visibilityState}`); }
  debugFreeze(): void { console.log(`Document freeze event, wasDiscarded is ${document.wasDiscarded}`); }
  debugResume(): void { console.log(`Document resume event, wasDiscarded is now ${document.wasDiscarded}`); }

  /*
    Dashboard APIs
  */
  updateDispatcher(): Promise<void> {
    return this.post(
      updateDispatcherAdminAreaPath(this.areaId));
  }

  updatePanic(panicTo: string): Promise<void> {
    return this.post(
      adminDispatchDashboardUpdatePanicPath(this.areaId),
      { panicTo: panicTo });
  }

  allocateDeliveryToDriver(deliveryId: number, driverId: number) : Promise<void> {
    return this.post(
      allocateAdminDispatchDashboardDeliveryPath(this.areaId, deliveryId),
      { driverId: driverId });
  }

  allocateDeliveryToGroup(deliveryId: number, groupId: number) : Promise<void> {
    return this.post(
      groupAdminDispatchDashboardDeliveryPath(this.areaId, deliveryId),
      { groupId: groupId });
  }

  allocateGroupToDriver(groupId: number, driverId: number) : Promise<void> {
    return this.post(
      allocateAdminDispatchDashboardGroupPath(this.areaId, groupId),
      { driverId: driverId });
  }

  lockGroup(groupId: number): Promise<void> {
    return this.post(
      lockAdminDispatchDashboardGroupPath(this.areaId, groupId),
      null);
  }

  unlockGroup(groupId: number): Promise<void> {
    return this.post(
      unlockAdminDispatchDashboardGroupPath(this.areaId, groupId),
      null);
  }

  mergeGroups(fromGroupId: number, toGroupId: number): Promise<void> {
    return this.post(
      mergeAdminDispatchDashboardGroupPath(this.areaId, fromGroupId),
      { toGroupId: toGroupId });
  }

  compactGroups(): Promise<void> {
    return this.post(
      compactAdminDispatchDashboardGroupsPath(this.areaId),
      null);
  }

  sendDelayMessage(deliveryId: number, delay: string, reason: string): Promise<void> {
    return this.post(
      sendDelayMessageAdminDispatchDashboardDeliveryPath(this.areaId, deliveryId),
      { delay: delay, reason: reason });
  }

  delayPrep(deliveryId: number, delay: string): Promise<void> {
    return this.post(
      delayPrepAdminDispatchDashboardDeliveryPath(this.areaId, deliveryId),
      { delayBy: delay });
  }

  updateDashNote(deliveryId: number, note: string): Promise<void> {
    return this.post(
      updateDashNoteAdminDispatchDashboardDeliveryPath(this.areaId, deliveryId),
      { note });
  }

  addDriver(driverId: number): Promise<void> {
    return this.post(
      addAdminDispatchDashboardDriverPath(this.areaId, driverId),
      null);
  }

  removeDriver(driverId: number): Promise<void> {
    return this.post(
      removeAdminDispatchDashboardDriverPath(this.areaId, driverId),
      null);
  }

  createDriverNote(driverId: number, note: string): Promise<void> {
    return this.post(
      createNoteAdminDispatchDashboardDriverPath(this.areaId, driverId),
      { note: note });
  }

  async fetchDeliveryDetails(deliveryId: number) : Promise<Item[]> {
    const json = await this.getJson<{ items: Item[] }>(
      detailsAdminDispatchDashboardDeliveryPath(this.areaId, deliveryId));
    return json.items;
  }

  /*
    Business logic
  */
  isDriverAlreadyDelivering(driverId: number): boolean {
    const existingDeliveriesOnDriver = this.dashboard?.deliveriesForDriver(driverId) ?? [];

    return (existingDeliveriesOnDriver.length > 0 && existingDeliveriesOnDriver.every(o => o.pickedUpAt !== null));
  }

  isDriverHoldingDeliveries(driverId: number): boolean {
    const existingDeliveriesOnDriver = this.dashboard?.deliveriesForDriver(driverId) ?? [];

    return (existingDeliveriesOnDriver.length > 0 && existingDeliveriesOnDriver.some(o => o.pickedUpAt !== null));
  }

  /*
    API primitives
  */
  private async getJson<T = unknown>(path: string): Promise<T> {
    return fetch(path, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => {
      if (resp.status > 299) throw "Bad HTTP status";
      return resp.json();
    });
  }

  private async post<T = unknown>(path: string, body?: T): Promise<void> {
    return fetch(path, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrfToken,
       },
      body: body ? JSON.stringify(body) : "",
    }).then((resp) => {
      if (resp.status > 299) throw "Bad HTTP status";
    });
  }

  /*
    ActionCable updates
  */
  subscribeToUpdates() : void {
    this.subscriptions.push(adminCable().subscriptions.create({ channel: "DispatchDashboardChannel", area_id: this.areaId }, {
      received: (data: DispatchDashboardChannelData) => {
        this.onDispatchDashboardChannelData(data);
      },
      disconnected: () => {
        console.log("Disconnected from dispatch dashboard updates");
        this.disconnectedAt = Date.now();
        this.dashboardConnected.notifyListeners(false);
      },
      connected: () => {
        console.log("Connected to dispatch dashboard updates");
        if (this.disconnectedAt) {
          const duration = Date.now() - this.disconnectedAt;
          this.disconnectedAt = undefined;
          Sentry.captureException(`Dispatch dashboard action cable reconnected`, { extra: { duration: duration } });
        }
        this.dashboardConnected.notifyListeners(true);
      }
    }));

    const handleDriverUpdate = this.onDriverUpdatesChannelData.bind(this);
    this.subscriptions.push(adminCable().subscriptions.create({ channel: "DriverUpdatesChannel", area_id: this.areaId }, {
      received(data: { driver: unknown }) { handleDriverUpdate(data); }
    }));

    const handleDispatchTrackingChannelData = this.onDispatchTrackingChannelData.bind(this);
    this.subscriptions.push(adminCable().subscriptions.create({ channel: "DispatchTrackingChannel", area_id: this.areaId }, {
      received(data: DispatchTrackingChannelData) { handleDispatchTrackingChannelData(data); }
    }));
  }

  unsubscribeFromUpdates() : void {
    this.subscriptions.forEach((subscription) => { subscription.unsubscribe(); });
    this.subscriptions.length = 0;
  }

  private updateOneRecord<T>(oldRecords: (T & { id: number })[], newRecord: (T & { id: number })): T[] {
    const newRecords = oldRecords.map(oldRecord => {
      if (oldRecord.id == newRecord.id) {
        return newRecord;
      } else {
        return oldRecord;
      }
    });

    if (!newRecords.includes(newRecord)) {
      newRecords.push(newRecord);
    }

    return newRecords;
  }

  private async onDispatchDashboardChannelData(data: DispatchDashboardChannelData) {
    if (data.dashboard != undefined) {
      const decodedDashboard = Dashboard.decoder.decode(data.dashboard);
      if (!decodedDashboard.isOk()) throw decodedDashboard.error;
      const updatedDashboard = decodedDashboard.value;

      this.dashboard = updatedDashboard;
    }

    if (this.dashboard && data.delivery != undefined) {
      const decodedDelivery = Delivery.decoder.decode(data.delivery);
      if (!decodedDelivery.isOk()) throw decodedDelivery.error;
      const updatedDelivery = decodedDelivery.value;

      const updatedDeliveries = updatedDelivery.deliveryStatus != DeliveryStatus.DropoffComplete && updatedDelivery.deliveryStatus != DeliveryStatus.ReturnComplete && updatedDelivery.deliveryStatus != DeliveryStatus.Cancelled ?
        this.updateOneRecord(this.dashboard.deliveries, updatedDelivery) :
        this.dashboard.deliveries.filter((d) => d.id != updatedDelivery.id);
      this.dashboard = this.dashboard.withChanges({ deliveries: updatedDeliveries });
    }

    if (this.dashboard && data.group != undefined) {
      const decodedDeliveryGroup = DeliveryGroup.decoder.decode(data.group);
      if (!decodedDeliveryGroup.isOk()) throw decodedDeliveryGroup.error;
      const updatedDeliveryGroup = decodedDeliveryGroup.value;

      const updatedDeliveryGroups = this.updateOneRecord(this.dashboard.groups, updatedDeliveryGroup);
      updatedDeliveryGroups.sort((a, b) => a.groupNumber - b.groupNumber);
      this.dashboard = this.dashboard.withChanges({ groups: updatedDeliveryGroups });
    }

    if (this.dashboard && data.areaOpen != undefined) {
      this.dashboard = this.dashboard.withChanges({ areaOpen: new Date(data.areaOpen) });
    }

    if (this.dashboard && data.areaClosed != undefined) {
      this.dashboard = this.dashboard.withChanges({ areaClosed: new Date(data.areaClosed) });
    }

    if (this.dashboard && data.dispatcherId != undefined) {
      this.dashboard = this.dashboard.withChanges({ dispatcherId: data.dispatcherId });
    }
    if (this.dashboard && data.dispatcherName != undefined) {
      this.dashboard = this.dashboard.withChanges({ dispatcherName: data.dispatcherName });
    }

    if (this.dashboard && data.nextAvailablePickupTime != undefined) {
      const newNextAvailablePickupTime = new Date(data.nextAvailablePickupTime);
      this.dashboard = this.dashboard.withChanges({ nextAvailablePickupTime: newNextAvailablePickupTime });
    }

    if (this.dashboard) {
      this.dashboardUpdated.notifyListeners(this.dashboard);
    }
  }

  private async onDriverUpdatesChannelData(data: { driver: unknown }) {
    if (!this.dashboard) return;

    const decodedDriver = Driver.decoder.decode(data["driver"]);
    if (!decodedDriver.isOk()) throw decodedDriver.error;
    const updatedDriver = decodedDriver.value;

    const updatedDrivers = updatedDriver.areaId == this.areaId ?
      this.updateOneRecord(this.dashboard.drivers, updatedDriver) :
      this.dashboard.drivers.filter((d) => d.id != updatedDriver.id);

    this.dashboard = this.dashboard.withChanges({ drivers: updatedDrivers.sort((a, b) => a.name.localeCompare(b.name)) });
    this.dashboardUpdated.notifyListeners(this.dashboard);
  }

  private async onDispatchTrackingChannelData(data: DispatchTrackingChannelData) {
    const deliveryTrackingList = data.deliveries.map((delivery) => {
      return {
        id: delivery.deliveryId,
        liveArrivalEstimate: delivery.secondsToDropoff == null ? undefined : new Date(Date.now() + delivery.secondsToDropoff*1000),
        liveLastUpdate: new Date(),
        routeToDropoff: delivery.routeToDropoff,
        routeToPickup: delivery.routeToPickup,
      };
    });

    this.deliveryTrackingByDriverId.set(data.driverId, deliveryTrackingList);
    this.driverTrackingUpdated.notifyListeners({
      driverId: data.driverId,
      latitude: data.latitude,
      longitude: data.longitude,
      receivedAt: new Date(data.receivedAt),
      deliveryTrackingList: deliveryTrackingList
    });
  }

  deliveryTrackingForDriver(driverId: number): DeliveryTracking[] {
    return this.deliveryTrackingByDriverId.get(driverId) ?? [];
  }
}

export class DashboardConfig {
  apiClient: ApiClientInterface;

  constructor(apiClient: ApiClientInterface) {
    this.apiClient = apiClient;
  }
}

class MissingDashboardConfig extends Error {}
export const DashboardContext = createContext<DashboardConfig | null>(null);
export const useDashboardConfig = (): DashboardConfig => {
  const maybeConfig = useContext(DashboardContext);

  if (!maybeConfig) {
    throw new MissingDashboardConfig();
  }

  return maybeConfig;
}
