import React, { useCallback } from "react";
import Modal from "src/admin/modal";

import { TimeSlot } from "./model";
import { classNames } from "src/common_util";
import { useDashboardConfig } from "./dispatch_api_client";

const HeatMap = ({
  slots,
} : {
  slots: TimeSlot[];
}) => {
  const timeSlotNodes = slots.map(timeSlot => {
    return (
      <TimeSlotNode
        key={timeSlot.time}
        timeSlot={timeSlot}
      />
    )
  });

  return (
    <div className="columns is-gapless is-mobile heatmap mb-4">
      {timeSlotNodes}
      <OtherTimeNode />
    </div>
  );
}

const TimeSlotNode = ({
  timeSlot,
} : {
  timeSlot: TimeSlot;
}) => {
  const { apiClient } = useDashboardConfig();

  const updatePanic = useCallback(async () => {
    const confirmMessage = `Are you sure you want to panic out ${timeSlot.time} pickups?`;

    if (confirm(confirmMessage) === true) {
      await apiClient.updatePanic(timeSlot.unformattedTime);
    }
  }, [apiClient, timeSlot.time, timeSlot.unformattedTime]);

  let classes = classNames({
    "pt-2": true,
    "panicked": timeSlot.isOperatingTime && timeSlot.panicked,
    "unpanicked": timeSlot.isOperatingTime && !timeSlot.panicked,
    "closed": !timeSlot.isOperatingTime
  });
  classes += " " + timeSlot.colour;

  return (
    <div className="column has-text-centered has-background-white">
      <a className={classes} onClick={updatePanic}>
        {timeSlot.time}

        <span className="mt-1 py-1">
          {timeSlot.rating}
        </span>
      </a>
    </div>
  )
}

const OtherTimeNode = () => {
  const { apiClient } = useDashboardConfig();

  const requestPanic = useCallback(async () => {
    const time = new Date();
    time.setTime((Math.round(time.getTime() / 300000) - 1) * 300000);

    const endTime = new Date();
    endTime.setTime(time.getTime() + 24*60*60*1000);

    const options = [];

    while (time < endTime) {
      time.setTime(time.getTime() + 300*1000);
      options.push(`<option value="${time.toISOString()}">${time.toLocaleString("en", { timeStyle: "short" })}</option>`);
    }

    // Insert a separator line after the time slots that are also visible on the heatmap
    //   determined by the loop count in timeSlotsVisibleOnHeatmap()
    options.splice(15, 0, `<option disabled="true">────────────</option>`);

    Modal.show({
      content: `
        <div class="field">
          <div class="control">
            <label class="label is-inline-block">Panic out pickups to</label>
            <div class="select is-inline-block not-top-aligned">
              <select data-form-validator-target="requiredField">
                ${options.join()}
              </select>
            </div>
          </div>
        </div>`,
      title: "Panic",
      onAccept: async (content: HTMLElement) => {
        const select = content.querySelector("select");
        if (select) apiClient.updatePanic(select.value);
      },
    }).catch(() => undefined);
  }, [apiClient]);

  return (
    <div className="column has-text-centered has-background-white later">
      <a className="dash-time-slot clear-time-slot unpanicked" onClick={requestPanic}>
        <i className="fas fa-angle-double-right"></i>
      </a>
    </div>
  )
}

export { HeatMap };
