import React, { useCallback, useMemo, useEffect, useState, useContext } from "react";
import { useDroppable } from "@dnd-kit/core";

import { Driver, Delivery, DeliveryTracking, DriverTrackingUpdate, Dashboard } from "./model";
import { DriverShiftStatus } from "../driver_shift_status";
import { DeliveryStatus } from "../delivery_status";
import { DeliveryComponent, MaplinkNode, CurrentTimeContext } from "./delivery";
import { Intl, Temporal } from "@js-temporal/polyfill";

import { classNames } from "src/common_util";
import { adminDeliveriesPath } from "src/routes";
import Modal from "src/admin/modal";
import { useDashboardConfig } from "./dispatch_api_client";

interface DriverComponentProps {
  dashboard: Dashboard;
  driver: Driver;
};

const DriverComponent = ({ dashboard, driver }: DriverComponentProps) => {
  const { apiClient } = useDashboardConfig();
  const [expanded, setExpanded] = useState(false);
  const [deliveryTrackingList, setDeliveryTrackingList] = useState<DeliveryTracking[]>([]);

  const currentTime = useContext(CurrentTimeContext);

  /*
  Because the delivery tracking data is updated so much more frequently than everything else on
  the dash - 10-25Hz on a busy dash - we've invested the extra effort to have each driver
  component take care of its own delivery tracking state, using its own update subscription,
  rather than having it passed down from the top level. This massively reduces the amount of
  work React needs to do to figure out which components need re-rendering. In the actual
  dashboard view, we don't really need to bother initializing this state on mount, as the
  Driver components are long-lived, but in the chat case (and other potential future changes
  where Driver components might be shown and removed more frequently), it matters as we don't
  want admins to have to wait for the next update to come in before a newly-created component
  starts to show the tracking data.
  */
  useEffect(() => {
    const updateDriverTrackingState = (driverTrackingUpdate: DriverTrackingUpdate) => {
      if (driverTrackingUpdate.driverId == driver.id) {
        setDeliveryTrackingList(driverTrackingUpdate.deliveryTrackingList)
      }
    }

    apiClient.driverTrackingUpdated.addListener(updateDriverTrackingState);

    setDeliveryTrackingList(apiClient.deliveryTrackingForDriver(driver.id));

    return () => {
      apiClient.driverTrackingUpdated.removeListener(updateDriverTrackingState);
    }
  }, [driver.id, apiClient]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }


  const { isOver, setNodeRef } = useDroppable({
    id: `driver:${driver.id}`,
    data: {
      id: driver.id,
      type: "driver"
    }
  });

  const classes = isOver ? "has-draggable-hover" : "";


  const handleCreateDriverNote = () => {
    Modal.show({
      content: `
        <div class="field">
          <div class="control">
            <label class="label">Note</label>
            <textarea class="textarea" data-form-validator-target="requiredField" placeholder="Note about what happened for the shift report - e.g. 'Driver was consistently running late all night'"></textarea>
          </div>
        </div>`,
      title: "New driver note",
      onAccept: async (content: HTMLElement) => {
        await apiClient.createDriverNote(driver.id, content.querySelector("textarea")!.value);
      },
    }).catch(() => undefined);
  }

  const handleRemoveDriver = useCallback(async (): Promise<void> => {
    if (apiClient.isDriverHoldingDeliveries(driver.id)) {
      if (!await Modal.show({
        title: "Are you sure?",
        content: "This driver has active deliveries that they haven't marked dropped off. Removing them from the dash will clear their deliveries off the dash without recording the time and location, or notifying the customer properly.<br/><br/>You should only do this if you are sure the driver has finished for the day and can't be reached to drop off the deliveries properly. You may need to contact customers yourself.",
        acceptButtonLabel: "Remove driver and deliveries",
      }).catch(() => undefined)) return;
    }

    apiClient.removeDriver(driver.id);
  }, [driver.id, apiClient]);

  const handleAddDriver = useCallback(async () => {
    await apiClient.addDriver(driver.id);
  }, [driver.id, apiClient]);


  const vehicleIcon = useMemo(() => {
    switch (driver.vehicleType) {
      case "Car":
        return <span title="Has car" className="icon is-car">&#128663;</span>;
      case "Scooter":
        return <span title="Has scooter" className="icon is-scooter">&#128757;</span>
      case "Motorbike":
        return <span title="Has motorbike" className="icon is-motorbike">&#127949;</span>
      case "E-bike":
        return <span title="Has e-bike" className="icon is-bicycle">&#128690;</span>;
      case "E-scooter":
        return <span title="Has e-scooter" className="icon is-escooter">&#128756;</span>;
    }
  }, [driver.vehicleType]);

  const ratingIcon = useMemo(() => {
    if (driver.rating === null) {
      return <span title="Is new driver" className="icon is-new-driver">&#128118;</span>;
    }

    return <span className="is-flex is-align-items-baseline" title={"Has " + driver.rating.toString() + " star rating"}>
      <span className="icon has-text is-star-rating">
        &#11088;
      </span>

      <span className="is-size-7">
        {driver.rating}
      </span>
    </span>;
  }, [driver.rating]);

  const alcoholIcon = useMemo(() => {
    if (driver.alcoholDeliveryAllowed) {
      return <span title="Can deliver alcohol" className="icon can-deliver-alcohol">&#127863;</span>;
    }
    return <></>;
  }, [driver.alcoholDeliveryAllowed]);

  const curfewIcon = useMemo(() => {
    if (driver.tenPmCurfew) {
      return <span title="Has driving curfew" className="icon has-driving-curfew">&#128345;</span>;
    }
    return <></>;
  }, [driver.tenPmCurfew]);

  const experienceIcon = useMemo(() => {
    if (driver.treatAsInexperienced) {
      return <span title="Inexperienced" className="icon is-inexperienced">&#9888;&#65039;</span>;
    }
    return <></>;
  }, [driver.treatAsInexperienced]);


  const dropoffSequenceOrder = (a: Delivery, b: Delivery): number => {
    if (a.dropoffSequence != b.dropoffSequence) {
      return a.dropoffSequence - b.dropoffSequence;
    } else if (a.dropoffTimeIso8601 != b.dropoffTimeIso8601) {
      return a.dropoffTimeIso8601.valueOf() - b.dropoffTimeIso8601.valueOf();
    } else {
      return a.id - b.id;
    }
  }

  const pickupSequenceOrder = (a: Delivery, b: Delivery): number => {
    if (a.pickupSequence != b.pickupSequence) {
      return a.pickupSequence - b.pickupSequence;
    } else if (a.pickupTimeIso8601 != b.pickupTimeIso8601) {
      return a.pickupTimeIso8601.valueOf() - b.pickupTimeIso8601.valueOf();
    } else {
      return a.id - b.id;
    }
  }

  const relevantDeliveries = dashboard.deliveriesForDriver(driver.id);
  const deliveringNow = relevantDeliveries.every(delivery => delivery.pickedUpAt != null);

  const deliveryComponentsGroup = (title: string | undefined, status: DeliveryStatus, sortFn: (a: Delivery, b: Delivery) => number) => {
    const deliveries = relevantDeliveries.filter(delivery => delivery.deliveryStatus == status);
    deliveries.sort(sortFn);

    const pickedUp = deliveries.every(delivery => delivery.pickedUpAt != null);
    const headerClasses = classNames({
      "category": true,
      "has-text-weight-medium": true,
      "py-1": true,
      "px-4": true,
      "has-background-grey-lighter": pickedUp,
      "has-background-grey-dark": !pickedUp,
      "has-text-white": !pickedUp,
    });

    return deliveries.length == 0 ? <></> : <>
      <div>
        <span className={headerClasses}>{title}</span>
        {deliveryComponents(deliveries)}
      </div>
    </>;
  }

  const deliveryComponents = (deliveries: Delivery[]) => {
    return deliveries.map(delivery => {
      return (
        <DeliveryComponent
          key={delivery.id}
          dashboard={dashboard}
          defaultExpand={false}
          delivery={delivery}
          deliveryTracking={deliveryTrackingFor(delivery)}
          deliveringNow={deliveringNow}
        />
      )
    });
  }

  const deliveryTrackingFor = (delivery: Delivery): DeliveryTracking | undefined => {
    return deliveryTrackingList?.find((tracking: DeliveryTracking) => tracking.id == delivery.id);
  }

  const renderActionButton = () => {
    if (driver.status == DriverShiftStatus.Active) {
      return (
        <div className="column has-text-right is-narrow">
          <button className="button is-small is-warning" onClick={handleRemoveDriver}>Remove</button>
        </div>
      );
    } else if (![DriverShiftStatus.Onboarding, DriverShiftStatus.Offboarding].includes(driver.status)) {
      return (
        <div className="column has-text-right is-narrow">
          <button className="button is-small is-success" onClick={handleAddDriver}>Add</button>
        </div>
      );
    }
  }

  const timeRange = (driver: Driver): React.JSX.Element => {
    if (driver.startTime && driver.endTime) {
      const formatter = new Intl.DateTimeFormat('en-NZ', { timeStyle: "short" });

      const start = formatter.format(driver.startTime);
      const end = formatter.format(driver.endTime);

      const currentZonedDateTime = Temporal.Instant.from(currentTime.toISOString()).toZonedDateTimeISO("Pacific/Auckland");
      const sinceStart = driver.startTime.since(currentZonedDateTime).total("minutes");
      const sinceEnd = driver.endTime.since(currentZonedDateTime).total("minutes");

      let expandedRange = <>{start} – {end}</>;

      // Driver starting within the next 30 minutes
      if (sinceStart > 0 && sinceStart <= 30) {
        expandedRange = <><span className="driver-start-time">{start}</span> – {end}</>;
      }

      // Driver finishing within the next 30 minutes, or already finished (< 0)
      else if (sinceEnd <= 30) {
        expandedRange = <>{start} – <span className="driver-end-time">{end}</span></>;
      }

      const hasUpcomingShifts = driver.upcomingShiftTimes.length > 0;
      const tooltip: { "data-tooltip"?: string } = {};

      if (hasUpcomingShifts) {
        const upcomingShiftTimes: string[] = driver.upcomingShiftTimes.map(shift => formatter.format(shift.startTime) + " – " + formatter.format(shift.endTime));
        tooltip["data-tooltip"] = upcomingShiftTimes.join("\r\n");
      }

      return <div className="has-text-grey pb-1 px-2">
        <span className="has-text-grey has-tooltip-arrow" {...tooltip}>
          <span className="icon is-small">
            <i className="far fa-sm fa-clock"></i>
          </span>
          {expandedRange}
          {hasUpcomingShifts ? " +" : ""}
        </span>
      </div>;
    } else {
      return <></>;
    }
  }

  const deliveriesUrl = (driverId: number) => {
    const date = currentTime.toLocaleDateString("en-NZ").replaceAll("/", "-");

    return adminDeliveriesPath({
      "order_filter[driver_id]": driverId.toString(),
      "order_filter[start_date]": date,
      "order_filter[end_date]": date
    })
  }

  const expandedDriverInfo = expanded ?
    <div className="p-2">
      <a className="button is-fullwidth is-small" href={`/admin/drivers/${driver.id}`} target="_blank" rel="noreferrer">
        <span>Driver</span>
      </a>

      <div className="columns is-gap-1 my-2 px-3">
        <div className="column p-0">
          <a className="button is-fullwidth is-small" href={deliveriesUrl(driver.id)} target="_blank" rel="noreferrer">
            <span>Deliveries</span>
          </a>
        </div>

        {driver.shiftId ?
          <div className="column p-0">
            <a className="button is-fullwidth is-small" href={`/admin/driver_shifts/${driver.shiftId}/edit`} target="_blank" rel="noreferrer">
              <span>Availability</span>
            </a>
          </div> : <></>}
      </div>

      <div className="columns is-vcentered is-gapless">
        <div className="column">
          <span className="icon">
            <i className="fas fa-phone"></i>
          </span>
          <a data-number={driver.mobileNumber} data-action="click->phone-widget#call">{driver.mobileNumber}</a>
        </div>

        <div className="column has-text-right is-narrow mr-2">
          <button className="button is-small" onClick={handleCreateDriverNote}>Add note</button>
        </div>

        {renderActionButton()}

      </div>
    </div>
    : <></>;

  const expando = useMemo(() => {
    if (expanded) {
      return <span className="icon"><i className="fas fa-chevron-up ml-2"></i></span>;
    }
    return <span className="icon"><i className="fas fa-chevron-down ml-2"></i></span>;
  }, [expanded]);

  const waiting = useMemo(() => {
    if (driver.requestedDeliveriesAt === null) {
      return <></>;
    }

    const freeAt = new Date(driver.requestedDeliveriesAt);
    return <div><span className="category is-free-driver has-text-weight-medium py-1 px-3">Free at {freeAt.getHours() % 12 || 12}:{String(freeAt.getMinutes()).padStart(2, '0')}{freeAt.getHours() >= 12 ? "PM" : "AM"}, {driver.requestedDeliveriesLocation}</span></div>;
  }, [driver.requestedDeliveriesAt, driver.requestedDeliveriesLocation]);

  const arrivalTimeOrHighNumber = (delivery: Delivery) => {
    return deliveryTrackingFor(delivery)?.liveArrivalEstimate?.getTime() ?? 9000000000000 + delivery.id;
  }
  const collecting = relevantDeliveries.some((delivery) => delivery.pickedUpAt === null);
  const lastDelivery = relevantDeliveries.length > 0 ? relevantDeliveries.reduce((prev, delivery) => arrivalTimeOrHighNumber(delivery) > arrivalTimeOrHighNumber(prev) ? delivery : prev) : undefined;
  const lastDeliveryArrivalTime = lastDelivery ? deliveryTrackingFor(lastDelivery)?.liveArrivalEstimate : undefined;
  const busyUntil = lastDelivery && lastDeliveryArrivalTime ? <div>
    <span className={"category has-text-weight-medium py-1 px-3 " + (collecting ? "is-driver-free-at-collecting" : "is-driver-free-at")}>
      Last drop-off estimated {lastDeliveryArrivalTime?.toLocaleString("en", { timeStyle: "short" }).replace(' ', '').toLowerCase()}, {lastDelivery.suburb}
    </span>
  </div> : <></>;

  const internalNote = useMemo(() => {
    if (driver.internalNote === "") {
      return <></>;
    }
    return <div className="has-text-grey pb-1 px-2">
      <span className="has-text-grey">
        <span className="icon is-small">
          <i className="fas fa-exclamation-circle"></i>
        </span> {driver.internalNote}
      </span>
    </div>;
  }, [driver.internalNote]);

  const dashNote = useMemo(() => {
    if (driver.dashNote === "") {
      return <></>;
    }
    return <div className="has-text-grey pb-1 px-2">
      <span className="has-text-grey">
        <span className="icon is-small">
          <i className="far fa-sm fa-sticky-note"></i>
        </span> {driver.dashNote}
      </span>
    </div>;
  }, [driver.dashNote]);

  const maplink = useMemo(() => {
    if (relevantDeliveries.length < 2) {
      return <></>;
    }
    return (
      <div className="p-1 ml-auto">
        <MaplinkNode deliveries={relevantDeliveries} />
      </div>
    );
  }, [relevantDeliveries]);

  return (
    <div className="driver column">
      <div className={`card is-card-dashboard ${classes}`} ref={setNodeRef}>
        <header className="card-header" onClick={toggleExpanded}>
          <div className="card-header-title px-2 py-1">
            <div className="mr-2">
              {ratingIcon}
            </div>

            {driver.name}
            {expando}
          </div>

          <div className="card-header-icon px-3 py-1">
            {alcoholIcon}
            {experienceIcon}
            {curfewIcon}
            {vehicleIcon}
          </div>

          {maplink}

          <div className="card-header-footer px-1 is-size-7">
            {internalNote}
          </div>

          <div className="card-header-footer px-1 is-size-7">
            {timeRange(driver)}
          </div>

          <div className="card-header-footer px-1 is-size-7">
            {dashNote}
          </div>
        </header>

        <div className="card-content p-0">
          <div className="card-header-expanded">
            {expandedDriverInfo}
          </div>
          {deliveryComponentsGroup('Return now', DeliveryStatus.ReturnNow, dropoffSequenceOrder)}
          {deliveryComponentsGroup('Return required', DeliveryStatus.ReturnLater, dropoffSequenceOrder)}
          {deliveryComponentsGroup('Arriving for dropoff', DeliveryStatus.DropoffArriving, dropoffSequenceOrder)}
          {deliveryComponentsGroup('Heading to dropoff', DeliveryStatus.DropoffNow, dropoffSequenceOrder)}
          {deliveryComponentsGroup('Dropoff later', DeliveryStatus.DropoffLater, dropoffSequenceOrder)}
          {deliveryComponentsGroup('Dropoff reassigned', DeliveryStatus.DropoffReassigned, dropoffSequenceOrder)}
          {deliveryComponentsGroup('Picked up already', DeliveryStatus.PickupComplete, dropoffSequenceOrder)}
          {deliveryComponentsGroup('Arrived for pickup', DeliveryStatus.PickupArrived, pickupSequenceOrder)}
          {deliveryComponentsGroup('Arriving for pickup', DeliveryStatus.PickupArriving, pickupSequenceOrder)}
          {deliveryComponentsGroup('Releasing for pickup', DeliveryStatus.PickupNearby, pickupSequenceOrder)}
          {deliveryComponentsGroup('Heading to pickup', DeliveryStatus.PickupNow, pickupSequenceOrder)}
          {deliveryComponentsGroup('Pickup later', DeliveryStatus.PickupLater, pickupSequenceOrder)}
          {deliveryComponentsGroup('Not accepted by driver', DeliveryStatus.Assigned, pickupSequenceOrder)}
          {waiting}
          {busyUntil}
        </div>
      </div>
    </div>
  );
}

export { DriverComponent };
