import React, { useEffect } from "react";
import { Client, ChatType } from "./client";
import { Conversation, HistoryStatus } from "./conversation";
import { DriverShiftStatus } from "../driver_shift_status";
import { Dashboard, Driver } from "../dispatch/model";
import { classNames } from "src/common_util";

const ShiftStatusOrder = [
  DriverShiftStatus.Active,
  DriverShiftStatus.RosteredButInactive,
  DriverShiftStatus.RosteredToStartSoon,
  DriverShiftStatus.Inactive,
  DriverShiftStatus.Onboarding,
  DriverShiftStatus.Offboarding,
  DriverShiftStatus.Unavailable,
];

const ConversationList = ({
  conversations,
  focusConversation,
  client,
  dashboard,
}: {
  conversations: Conversation[];
  focusConversation: (selectedConversation: Conversation) => void;
  client: Client;
  dashboard?: Dashboard;
}): React.JSX.Element => {
  const groupByAreaAndStatus = (items: Conversation[]) => {
    return items.reduce((accl, item: Conversation) => {
      if (!accl.has(item.areaId)) accl.set(item.areaId, new Map());

      const areaMap = accl.get(item.areaId);

      if (!areaMap) throw "Somehow missing areaMap we just created";

      if (!areaMap.has(item.driverShiftStatus))
        areaMap.set(item.driverShiftStatus, []);

      areaMap.get(item.driverShiftStatus)?.push(item);

      return accl;
    }, new Map<number, Map<DriverShiftStatus, Conversation[]>>());
  };

  const totalUnreads = conversations.reduce((sum, convo) => convo.unreadMessages + sum, 0)

  useEffect(() => {
    totalUnreads > 0 ? document.title = `(${totalUnreads}) ` + document.originalTitle : document.title = document.originalTitle;
  }, [totalUnreads]);

  const groupedConversations = groupByAreaAndStatus(conversations);

  const sectionElements = Array.from(groupedConversations).map(
    ([areaId, areaMap]: [number, Map<DriverShiftStatus, Conversation[]>]) => {
      const first = areaMap.values().next()?.value[0];
      const unreadsForSectionToArray = areaMap.values()
      const unreadsForSection = Array.from(unreadsForSectionToArray).reduce((sum, convos) => {
        return sum + convos.reduce((sum, convo) => convo.unreadMessages + sum, 0)
      }, 0)
      return (
        <ConversationListSection
          label={first?.areaName}
          unreadMessages={unreadsForSection}
          statusSections={areaMap}
          focusConversation={focusConversation}
          client={client}
          dashboard={dashboard}
          key={`areaid:${areaId}`}
        />
      );
    }
  );

  return (
    <div className="px-4 pb-4 has-vertical-scrollbars">{sectionElements}</div>
  );
};

const statusGroupingKeyToLabel = (groupingKey: DriverShiftStatus): string => {
  // These should ideally match the dispatcher dashboard for consistency
  switch (groupingKey) {
    case DriverShiftStatus.Active:
      return "Active";

    case DriverShiftStatus.RosteredButInactive:
      return "Rostered but not active";

    case DriverShiftStatus.RosteredToStartSoon:
      return "Rostered to start soon";

    case DriverShiftStatus.Inactive:
      return "Not active";

    case DriverShiftStatus.Unavailable:
      return "Unavailable";

    case DriverShiftStatus.Onboarding:
      return "Onboarding";

    case DriverShiftStatus.Offboarding:
      return "Offboarding";

    default:
      return "Unknown group " + groupingKey;
  }
};

const ConversationListSection = ({
  label,
  statusSections,
  focusConversation,
  unreadMessages,
  client,
  dashboard,
}: {
  label: string;
  unreadMessages: number;
  statusSections: Map<DriverShiftStatus, Conversation[]>;
  focusConversation: (c: Conversation) => void;
  client: Client;
  dashboard?: Dashboard;
}): React.JSX.Element => {

  const statusSectionItems = Array.from(statusSections)
    .sort(
      (
        [a1]: [DriverShiftStatus, Conversation[]],
        [b1]: [DriverShiftStatus, Conversation[]]
      ) => ShiftStatusOrder.indexOf(a1) - ShiftStatusOrder.indexOf(b1)
    )
    .map(
      ([status, conversations]: [
        status: DriverShiftStatus,
        conversations: Conversation[]
      ]) => {
        return (
          <StatusSectionItem
            status={status}
            defaultIsOpen={status == DriverShiftStatus.Active && client.chatType != ChatType.Manager}
            conversations={conversations}
            focusConversation={focusConversation}
            client={client}
            dashboard={dashboard}
            key={`section-${status}`}
          />
        );
      }
    );

  return (
    <div className="conversation-list">
      <div className="is-flex conversation-list--badge-wrapper">
        <p className="group-heading is-unselectable">{label}</p>
        <p className={unreadMessages == 0 ? 'unread-badge is-muted' : 'unread-badge'}>{unreadMessages > 99 ? '99+' : unreadMessages}</p>
      </div>

      {statusSectionItems}
    </div>
  );
};

const StatusSectionItem = ({
  status,
  defaultIsOpen,
  conversations,
  focusConversation,
  client,
  dashboard,
}: {
  status: DriverShiftStatus;
  defaultIsOpen: boolean;
  conversations: Conversation[];
  focusConversation: (c: Conversation) => void;
  client: Client;
  dashboard?: Dashboard;
}) => {
  let totalUnreads = 0;

  conversations.sort((a, b) => {
    const as = a.sortKey();
    const bs = b.sortKey();

    if (as < bs) return -1;
    else if (as > bs) return 1;
    else return 0;
  });

  const conversationListItems = conversations.map((c) => {
    totalUnreads += c.unreadMessages;

    return (
      <ConversationListItem
        conversation={c}
        key={c.uuid}
        onConversationSelected={focusConversation}
        dashboard={dashboard}
      />
    );
  });

  useEffect(() => {
    if (client.chatType != ChatType.Dispatcher || ![ DriverShiftStatus.Active, DriverShiftStatus.RosteredButInactive, DriverShiftStatus.RosteredToStartSoon ].includes(status)) return;

    const convosToLoad = conversations.filter(convo => convo.historyStatus == HistoryStatus.Unloaded);

    if (convosToLoad.length) {
      client.loadHistory(convosToLoad);
    }
  }, [client, conversations, status]);

  return (
    <details open={defaultIsOpen} className="group-collapsible">
      <summary>
        <div>
          <span className="icon">
            <i className="fas fa-caret-right"></i>
          </span>

          <span>{statusGroupingKeyToLabel(status)}</span>

          <span className={totalUnreads == 0 ? 'unread-badge is-muted' : 'unread-badge'}>{totalUnreads > 99 ? '99+' : totalUnreads}</span>
        </div>
      </summary>

      <div className="list has-hoverable-list-items has-overflow-ellipsis">
        {conversationListItems}
      </div>
    </details>
  );
};

const ConversationListItem = ({
  conversation,
  onConversationSelected,
  dashboard,
}: {
  conversation: Conversation;
  onConversationSelected: (conversation: Conversation) => void;
  dashboard?: Dashboard;
}) => {
  const driver: Driver | undefined = dashboard?.drivers.find(d => d.id == conversation.id);

  const unreadMessages = conversation.unreadMessages;
  const unreadBadge = unreadMessages > 0
    ? <span className="unread-badge">{unreadMessages > 99 ? '99+' : unreadMessages}</span>
    : <></>;

  const messageTimestamp = () => {
    const now = new Date();
    const lastMessage = conversation.messages.at(-1);

    if (lastMessage?.publishedAt && lastMessage.publishedAt.getTime() > new Date().setHours(now.getHours() < 5 ? -19 : 5, 0, 0, 0)) {
      const minutesSinceLastMessage = Math.floor((now.getTime() - lastMessage.publishedAt.getTime()) / 1000 / 60);
      const timestampText = minutesSinceLastMessage > 0 ? minutesSinceLastMessage + " min" : "Now";
      const timestampClass = lastMessage.authorDetails.authorType == "admin" ? "is-sent" : "";

      return <span className={"message-timestamp tag is-rounded " + timestampClass}>{timestampText}</span>;
    }

    return <></>;
  }


  const status = () => {
    return classNames({
      "list-item-image": true,
      "free": !!(driver && dashboard?.driversAwaitingDeliveries().includes(driver)),
      "idle": !!(driver && dashboard?.driversWithoutDeliveries().includes(driver))
    });
  }

  return (
    <div
      className={
        "list-item is-clickable " +
        `is-${conversation.driverShiftStatus}-driver` +
        (conversation.isActiveConversation ? " is-selected" : "")
      }
      onClick={() => {
        onConversationSelected(conversation);
      }}
    >
      <div className={status()}>
        <span className="icon">
          <i className="fas fa-user-circle" aria-hidden="true"></i>
        </span>
      </div>

      <div className="list-item-content">
        <div className="list-item-title has-text-weight-normal is-flex">
          <span>{conversation.name}</span>
        </div>
      </div>

      <div className="list-item-right is-flex is-gap-1">
        {messageTimestamp()}
        {unreadBadge}
      </div>
    </div>
  );
};

export { ConversationList };
