import * as Sentry from "@sentry/browser";
import { autocomplete } from '@algolia/autocomplete-js';
import { BaseItem } from "@algolia/autocomplete-core";

import MapSuburbsController, { SuburbListing } from "./map_suburbs_controller";
import { adminMapBlocksPath } from "src/routes";

import {
  adminMapBlockPath,
  findAdminMapBlocksPath,
} from 'src/routes';

interface MapBlockListing {
  id: number;
  suburb_id?: number;
  boundaries: string[];
}

interface MapBlockBounds {
  id: number;
  north: number;
  east: number;
  south: number;
  west: number;
}

export default class extends MapSuburbsController {
  static targets = ["map", "searchInputContainer", "suburbSelect"];

  declare suburbSelectTarget: HTMLSelectElement;
  declare hasSuburbSelectTarget: boolean;
  declare searchInputContainerTarget?: HTMLInputElement;
  declare hasSearchInputContainerTarget: boolean;

  csrfToken!: string;

  mapBlocks = new Map<number, MapBlockListing>();
  suburbs = new Map<number, SuburbListing>();

  // we can't initialize any Maps objects until it's loaded, and we need to run the Loader to do that
  infoWindow?: google.maps.InfoWindow;
  infoWindowOpen = false;

  autocompleteApi?: { setQuery: (query: string) => void; destroy: () => void };
  sessionToken?: google.maps.places.AutocompleteSessionToken;

  blockPolygons = new Map<number, google.maps.Polygon>();

  initialBlockId?: number;
  initialLatLng?: google.maps.LatLng;

  selectedBlock?: MapBlockListing;
  selectedPolygon?: google.maps.Polygon;
  selectedLatLng?: google.maps.LatLng;

  async connect(): Promise<void> {
    await super.connect();
    this.csrfToken = (document.querySelector("meta[name='csrf-token']") as HTMLMetaElement)?.content ?? "";
    this.setupAutocomplete();
  }

  disconnect(): void {
    super.disconnect();

    this.mapBlocks.clear();
    this.suburbs.clear();

    this.blockPolygons.forEach((block) => block.setMap(null));
    this.blockPolygons.clear();

    this.autocompleteApi?.destroy();

    this.infoWindow = this.selectedPolygon = this.selectedBlock = this.autocompleteApi = undefined;
  }

  protected createMap(): void {
    super.createMap();

    if (!this.map) return; // never happens

    this.infoWindow = new google.maps.InfoWindow();

    google.maps.event.addListener(this.infoWindow, "closeclick", () => {
      this.infoWindowOpen = false;
    });

    google.maps.event.addListener(this.map, "click", () => {
      this.closeInfoWindow();
      this.deselectBlock();
    });
  }

  protected setInitialBounds(): void {
    this.updateStateFromHash();
  }

  private updateHashFromState(): void {
    const bounds = this.map?.getBounds();
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      window.history.replaceState(undefined, "", `#n${ne.lat()},e${ne.lng()},s${sw.lat()},w${sw.lng()},b${this.selectedBlock?.id ?? ''},p0,lat${this.selectedLatLng?.lat() ?? ''},lng${this.selectedLatLng?.lng() ?? ''}`);
    }
  }

  private updateStateFromHash(): void {
    const match = window.location.hash?.match(/#n([\d.-]+),e([\d.-]+),s([\d.-]+),w([\d.-]+)(?:,b(\d*))?(?:,p(\d+))?(?:,lat([\d.-]+),lng([\d.-]+))?/)
    if (match) {
      const ne = new google.maps.LatLng(parseFloat(match[1]), parseFloat(match[2]));
      const sw = new google.maps.LatLng(parseFloat(match[3]), parseFloat(match[4]));
      this.map?.fitBounds(new google.maps.LatLngBounds(sw, ne), parseInt(match[6] ?? '200'));

      this.initialBlockId = parseInt(match[5]);
      this.initialLatLng = match[7] && match[8] ? new google.maps.LatLng(parseFloat(match[7]), parseFloat(match[8])) : undefined;
    }
  }

  protected boundsChanged(): void {
    this.updateHashFromState();

    const bounds = this.map?.getBounds();
    const pending = this.data.get("pending");
    if (bounds) this.loadDataFrom(`${this.addBoundsToPath(adminMapBlocksPath({ format: 'json' }), bounds)}&pending=${pending}`)
  }

  protected async loadDataFrom(path: string): Promise<void> {
    const response = await fetch(path, { credentials: 'same-origin' });
    const json = await response.json();

    for (const suburb of json.suburbs) {
      this.suburbs.set(suburb.id, suburb);
    }

    for (const block of json.mapBlocks) {
      this.mapBlocks.set(block.id, block);
      this.renderBlock(block);
    }
  }

  private renderBlock(block: MapBlockListing) {
    if (this.blockPolygons.has(block.id)) return;
    const paths = block.boundaries.map((polyline) => this.decodePolyline(polyline));
    const polygon = new google.maps.Polygon({
      map: this.map,
      paths: paths,
    });
    if (block.id == this.initialBlockId && this.initialLatLng) {
      this.setBlockStyle(polygon, block, true);
      this.blockSelected(polygon, block, this.initialLatLng);
    } else {
      this.setBlockStyle(polygon, block, false);
    }
    this.blockPolygons.set(block.id, polygon);

    polygon.addListener("click", (ev: google.maps.MapMouseEvent) => this.blockClicked(polygon, block, ev.latLng || undefined));
  }

  private setBlockStyle(polygon: google.maps.Polygon, block: MapBlockListing, selected: boolean): void {
    polygon.setOptions({
      fillColor: this.blockFillColor(block, selected),
      fillOpacity: !block.suburb_id ? 0.1 : (selected ? 0.6 : 0.4),
      strokeOpacity: selected ? 1 : 0.2,
      strokeColor: "rgba(50, 50, 50)",
      strokeWeight: 1,
    });
  }

  private blockFillColor(block: MapBlockListing, selected = false): string {
    if (block.suburb_id) {
      const suburb = this.suburbs.get(block.suburb_id);
      if (!suburb) return this.UNSERVICED_FILL_COLOR; // shouldn't ever happen
      return this.suburbFillColor(suburb)
    } else {
      return selected ? this.SELECTED_UNSERVICED_FILL_COLOR : this.UNSERVICED_FILL_COLOR;
    }
  }

  private closeInfoWindow(): void {
    this.infoWindow?.close();
    this.infoWindowOpen = false;
    this.selectedLatLng = undefined;
    this.updateHashFromState();
  }

  private deselectBlock(): void {
    if (this.selectedPolygon && this.selectedBlock) {
      this.setBlockStyle(this.selectedPolygon, this.selectedBlock, false);
      this.selectedBlock = this.selectedPolygon = undefined;
      this.updateHashFromState();
    }
  }

  private blockClicked(polygon: google.maps.Polygon, block: MapBlockListing, clickLatLng?: google.maps.LatLng): void {
    if (!clickLatLng) return;

    if (this.selectedPolygon == polygon && this.infoWindowOpen) {
      this.closeInfoWindow();
      return;
    }

    this.blockSelected(polygon, block, clickLatLng);
  }

  private blockSelected(polygon: google.maps.Polygon, block: MapBlockListing, clickLatLng: google.maps.LatLng): void {
    if (this.selectedPolygon && this.selectedBlock) {
      this.setBlockStyle(this.selectedPolygon, this.selectedBlock, false);
    }

    this.selectedBlock = block;
    this.selectedPolygon = polygon;
    this.selectedLatLng = clickLatLng;

    this.setBlockStyle(this.selectedPolygon, this.selectedBlock, true);

    this.openBlockInfo(this.selectedBlock, this.selectedLatLng);
    this.updateHashFromState();
  }

  private async openBlockInfo(block: MapBlockListing, clickLatLng: google.maps.LatLng): Promise<void> {
    const response = await fetch(adminMapBlockPath({ id: block.id }), { credentials: 'same-origin' });

    if (response.status == 200) {
      this.infoWindow?.setOptions({
        content: await response.text(),
        position: clickLatLng,
      });
      this.infoWindow?.open(this.map);
      this.infoWindowOpen = true;
    } else {
      Sentry.captureMessage(`MapBlocksController: displaying block info returned ${response.status}`);
    }
  }

  async submitBlockForm(ev: MouseEvent): Promise<void> {
    ev.preventDefault();

    if (this.hasSuburbSelectTarget && this.selectedPolygon && this.selectedBlock) {
      const updatedBlock = await this.saveBlockSuburbMapping(this.selectedBlock.id, this.suburbSelectTarget.value);
      this.selectedBlock.suburb_id = updatedBlock.suburb_id;
      this.setBlockStyle(this.selectedPolygon, this.selectedBlock, true);
    }

    this.infoWindow?.close();
    this.infoWindowOpen = false;
  }

  private async saveBlockSuburbMapping(blockId: number, suburbId: string): Promise<MapBlockListing> {
    const response = await fetch(adminMapBlockPath(blockId, { format: 'json' }), {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken,
       },
      body: JSON.stringify({ map_block: { suburb_id: suburbId } }),
    });
    if (response.status > 299) {
      Sentry.captureMessage(`MapBlocksController: updating block returned ${response.status}`);
      alert(await response.text());
    }

    const json = await response.json();
    return json["mapBlocks"][0];
  }

  private setupAutocomplete(): void {
    if (!this.hasSearchInputContainerTarget || !this.searchInputContainerTarget) return;

    this.autocompleteApi = autocomplete({
      container: this.searchInputContainerTarget,
      classNames: {
        form: "input",
        item: "p-2",
      },
      defaultActiveItemId: 0,
      initialState: { query: this.data.get("initial-value") ?? "" },
      getSources: () => {
        return [{
          sourceId: "autocomplete-address", // arbitrary
          getItems: async ({ query } : { query: string }): Promise<BaseItem[]> => {
            // Start a new session if we don't have one active, so we can get combined session pricing on the completion
            this.sessionToken ??= new google.maps.places.AutocompleteSessionToken();

            const request = {
              input: query,
              includedRegionCodes: ["nz"],
              language: "en-NZ",
              region: "nz",
              sessionToken: this.sessionToken,
            };
            const result = await google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(request);
            return result.suggestions.map((suggestion) => { return { id: suggestion.placePrediction?.placeId, prediction: suggestion.placePrediction } });
          },
          templates: {
            item({ item, html }) {
              const prediction = (item.prediction as google.maps.places.PlacePrediction);
              return html`<div>${prediction.mainText?.text}</div><div class="description">${prediction.secondaryText?.text}</div>`;
            },
            noResults({ state, html }) {
              return html`Sorry, couldn't find an address for "${state.query}"`;
            },
          },
          onSelect: async ({ item, setQuery }) => {
            // Calling toPlace() terminates the session, and we want to reset or else we'll get charged per-request thereafter.
            this.sessionToken = undefined;

            const prediction = (item.prediction as google.maps.places.PlacePrediction);
            const place = prediction.toPlace();
            await place.fetchFields({ fields: ['location'] });

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const latlng = place.location!;
            this.map?.setCenter(latlng);

            const blockWithBounds = await this.findMapBlock(latlng.lat(), latlng.lng());
            const polygon = blockWithBounds && this.blockPolygons.get(blockWithBounds.id);
            const block = blockWithBounds && this.mapBlocks.get(blockWithBounds.id);
            if (polygon && block) {
              // only select a specific block & open the info window for specific street addresses, as opposed to eg. towns or suburbs
              if (prediction.types.includes("street_address") || prediction.types.includes("premise") || prediction.types.includes("subpremise") || prediction.types.includes("establishment")) {
                this.blockSelected(polygon, block, latlng);
              } else {
                this.closeInfoWindow();
                this.deselectBlock();
              }
              const ne = new google.maps.LatLng(blockWithBounds.north, blockWithBounds.east);
              const sw = new google.maps.LatLng(blockWithBounds.south, blockWithBounds.west);
              this.map?.fitBounds(new google.maps.LatLngBounds(sw, ne), 200);
            }

            // Reset for the next input
            setQuery("");
          },
        }];
      },
    });
  }

  async findMapBlock(latitude: number, longitude: number): Promise<MapBlockBounds | undefined> {
    const response = await fetch(findAdminMapBlocksPath({ format: 'json', latitude: latitude, longitude: longitude }), {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken,
       },
    });
    if (response.status == 404) {
      return undefined;
    }
    if (response.status > 299) {
      Sentry.captureMessage(`MapBlocksController: finding block returned ${response.status}`);
      alert(await response.text());
    }
    const json = await response.json();
    return (json["mapBlock"] as MapBlockBounds);
  }
}
